<template>
  <div v-if="load" class="ti-xian">
    <el-card class="box-card">
      <div class="title">
        <p>
          <b>提现到银行卡</b>
          <span class="txt">
            可提现
            <span class="price">{{ canTiXianPrice }}</span>
            元
          </span>
        </p>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item label="银行卡号" prop="cardNumber">
          <el-input
            style="width: 70%"
            placeholder="输入银行卡号"
            v-model.trim="ruleForm.cardNumber"
            max="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bankName">
          <el-input
            style="width: 70%"
            placeholder="输入开户行"
            v-model.trim="ruleForm.bankName"
            max="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户人姓名" prop="people">
          <el-input
            style="width: 70%"
            placeholder="输入开户人姓名"
            v-model.trim="ruleForm.people"
            max="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            style="width: 70%"
            placeholder="当前登录账户的手机号"
            v-model.trim="ruleForm.phone"
            max="32"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input
            style="width: 20%"
            placeholder="输入短信验证码"
            v-model.trim="ruleForm.code"
            max="32"
          ></el-input>
          <el-button
            ref="yz_btn"
            style="margin-left: 15px"
            type="success"
            @click="getPhoneCode"
            :disabled="
              getPhoneCodeDate == '获取验证码' || getPhoneCodeDate == '验证中'
                ? false
                : true
            "
          >
            {{ getPhoneCodeDate }}
          </el-button>
        </el-form-item>
        <el-form-item label="提现金额" prop="price">
          <el-input
            style="width: 20%"
            placeholder="输入金额"
            @input="toNumber"
            @blur="ruleForm.price = Number(ruleForm.price)"
            :value="ruleForm.price"
            max="32"
          ></el-input>
          <span style="margin-left: 20px">元</span>
          <p style="font-size: 12px; color: #9b9b9b">
            <!-- 每笔提现金额需大于等于500元，且不超过{{ ruleForm.number }}元 -->
            每笔提现金额需大于等于500元
          </p>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="success" @click="submitForm('ruleForm')">
          提交申请
        </el-button>
        <!-- <span class="redcolor ml20 fs12" style="font-weight: bold">
          今日可提现：{{ ruleForm.num }}次
        </span> -->
        <div class="hint">
          <p>提现手续费说明：</p>
          <!-- <p class="mt10">1.提现到个人账户收取{{ruleForm.commission}}%手续费</p> -->
          <!-- <p class="mt10">2.提现到对公账户且开具专票收取{{ruleForm.commission}}%手续费</p> -->
          <p class="mt10">1.提现收取{{ruleForm.commission}}%手续费，详情参考<span class="cp" @click="toProtocol(4)">《叮叮开课代收款项代收服务协议》</span></p>
          <p class="mt10">2.提交后款项在7个工作日内到达您选择的银行账号</p>
        </div>
      </div>
    </el-card>
    <loading v-show="isLoading" leftPos="0"></loading>
  </div>
</template>

<script>
import background from '@/mixin/background'
import loading from '@/components/LoadGif'
import tencentCaptcha from '@/mixin/tencentCaptcha'

export default {
  components: {
    loading,
  },

  data() {
    return {
      isLoading: false,
      isShow: '',
      canTiXianPrice: 0, //可提现金额
      ruleForm: {
        cardNumber: '', //银行卡号
        bankName: '', //开户银行
        people: '', //开户人姓名
        phone: '', //预留手机号码
        code: '', // 验证码
        price: '', //提现金额
        num: '', //提现次数
        commission: '', //手续费
        number: '',
      },
      rules: {},
      load: false,
      getPhoneCodeDate: '获取验证码', //控制发送验证码时间
    }
  },

  mixins: [background, tencentCaptcha],

  destroyed() {
    clearInterval(this.run)
  },
  methods: {
    //获取手机验证码
    getPhoneCode() {
      if (!/^1[2-9]\d{9}$/.test(this.ruleForm.phone)) {
        this.$message({
          message: '请输入正确手机号',
          type: 'warning',
        })
        return false
      }
      try {
        this.Captcha.show()
      } catch (e) {
        this.$root.prompt('加载验证码失败，请检查网络或刷新重试')
      }
    },

    tencentCaptchaCallback(res) {
      let that = this
      this.isLoading = true
      that.$http({
        name: 'PhoneCodeInfo',
        url: '/Message/sendVerification',
        data: {
          mch_mobile: that.ruleForm.phone,
          type: 2,
          Ticket: res.ticket,
          Randstr: res.randstr,
        },
        callback({ code }) {
          that.isLoading = false
          if (code == 200) {
            let time = 60 //设置重新发送验证码时间
            that.run = setInterval(function () {
              that.getPhoneCodeDate = time + '秒'
              time--
              if (time <= 0) {
                that.getPhoneCodeDate = '获取验证码'
                time = 60
                clearInterval(that.run)
              }
            }, 1000)
          }
        },
        error: () => {
          this.isLoading = false
          that.getPhoneCodeDate = '获取验证码'
        },
      })
    },

    applyForTiXian() {
      //提现申请
      let that = this
      that.$http({
        name: 'applyForTiXianInfo',
        url: '/Wallet/withdrawalIndex',
        callback({ data }) {
          that.canTiXianPrice = data.cash_available //可提现金额
          that.ruleForm.cardNumber = data.bank_account
          that.ruleForm.bankName = data.open_bank
          that.ruleForm.people = data.account_name
          that.ruleForm.phone = data.umobile
          that.ruleForm.num = data.num
          that.ruleForm.commission = Number(data.commission) * 100
          that.ruleForm.number = data.number
          that.rules = {
            cardNumber: [
              { required: true, message: '请输入银行卡号', trigger: 'blur' },
              {
                pattern: /^[0-9]+$/,
                message: '请输入正确的银行卡号',
                trigger: 'blur',
              },
            ],
            bankName: [
              { required: true, message: '请输入开户行', trigger: 'blur' },
            ],
            people: [
              { required: true, message: '请输入开户人姓名', trigger: 'blur' },
            ],
            phone: [
              {
                required: true,
                message: '请输入预留手机号码',
                trigger: 'blur',
              },
              {
                pattern: /^1[2-9]\d{9}$/,
                message: '请输入正确的手机号码',
                trigger: 'blur',
              },
            ],
            code: [
              { required: true, message: '请输入验证码', trigger: 'blur' },
            ],
            price: [
              { required: true, message: '请输入提现金额', trigger: 'blur' },
              {
                min: 500,
                message: '输入金额小于500，请输入正确金额',
                trigger: 'blur',
                type: 'number',
              },
              {
                max: Number(that.canTiXianPrice || 999999),
                message: '输入金额超出余额，请输入正确金额',
                trigger: 'blur',
                type: 'number',
              },
            ],
          }
          that.load = true
        },
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //提交提现
          let that = this
          that.$http({
            name: 'submitTiXian',
            url: '/Wallet/submitWithdrawal',
            data: {
              account_name: that.ruleForm.people, //用户名
              bank_account: that.ruleForm.cardNumber, //银行账号
              cash_available: that.canTiXianPrice, //可提现金额
              open_bank: that.ruleForm.bankName, //开户银行
              mobile: that.ruleForm.phone, //电话号码
              type: 2, //短信验证码类型
              code: that.ruleForm.code, //验证码
              price: that.ruleForm.price, //提现金额
            },
            callback({ code, info }) {
              if (code == 200) {
                that.$root.prompt({
                  msg: '提现成功',
                  type: 'success',
                })
                that.$router.replace({ path: '/capitalAccount' })
              } else {
                if (info) {
                  that.$root.prompt({
                    msg: info,
                  })
                }
              }
            },
          })
        }
      })
    },

    toNumber(value) {
      let newValue = value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
      newValue = newValue ? newValue[0] : ''
      let formattedValue = newValue
      if (newValue) {
        if (newValue.indexOf('.') > 7) {
          formattedValue =
            newValue.split('.')[0].slice(0, 7) +
            '.' +
            newValue.split('.')[1].slice(0, 2)
        } else {
          formattedValue = newValue.slice(
            0,
            newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
          )
        }
      }
      this.ruleForm.price = formattedValue
    },
    /**
     * @name: toProtocol
     * @test: test font
     * @msg: 跳转协议页面
     * @param {none}
     * @return: none
     */
      toProtocol(val) {
        const { href } = this.$router.resolve({
            path: '/protocol',
            query: {
                type: val,
            },
        })
        this.protocolHref = href
        this.$store.dispatch('open', this.protocolHref)
    },
  },

  created() {
    let that = this
    that.canTiXianPrice = this.$route.params.canTiXianPrice || 0
    that.applyForTiXian()
  },
}
</script>

<style lang="less" scoped>
.cp {
    cursor: pointer;
}
.box-card {
  margin: 20px;
}
.price {
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 53, 53, 1);
}
.txt {
  font-size: 15px;
  margin-left: 20px;
}
/deep/ .el-form-item {
  margin-bottom: 30px;
}
/deep/ .el-input__inner {
  height: 36px;
}
.el-button {
  width: 200px;
  margin-left: 30px;
  color: #fff;
  background-color: #0aa29b;
  border-color: #0aa29b;
}
.el-button:hover {
  background-color: #0aa29b;
  border-color: #0aa29b;
}
.el-button:focus {
  background-color: #0aa29b;
  border-color: #0aa29b;
}
.title {
  margin-bottom: 30px;
}
.hint {
  font-size: 12px;
  color: #ff3535;
  margin-left: 30px;
  margin-top: 20px;
}
</style>
